import {NextAuthConfig} from "next-auth";

export const AuthConfig: NextAuthConfig = {
  debug: true,
  secret: process.env.NEXTAUTH_SECRET,
  pages: {
    signIn: "/auth/login",
    signOut: "/auth/login",
    error: "/auth/login"
  },
  session: {
    strategy: "jwt"
  },
  providers: []
};
