"use client";

import {FirebaseContextProvider} from "@/context/firebase/firebase.context";
import {NextUIProvider} from "@nextui-org/react";
import {SessionProvider} from "next-auth/react";
import {ThemeProvider as NextThemesProvider} from "next-themes";
import {ThemeProviderProps} from "next-themes/dist/types";
import {useRouter} from "next/navigation";
import * as React from "react";

export interface ProvidersProps {
  children: React.ReactNode;
  themeProps?: ThemeProviderProps;
}

export function Providers({children, themeProps}: ProvidersProps) {
  const router = useRouter();

  return (
    <SessionProvider>
      <FirebaseContextProvider>
        <NextUIProvider navigate={router.push}>
          <NextThemesProvider {...themeProps}>{children}</NextThemesProvider>
        </NextUIProvider>
      </FirebaseContextProvider>
    </SessionProvider>
  );
}
