"use client";

import {FirebaseConfig} from "@/libs/config";
import {
  Analytics,
  getAnalytics,
  initializeAnalytics,
  logEvent
} from "firebase/analytics";
import {getApp, getApps, initializeApp} from "firebase/app";
import {usePathname, useSearchParams} from "next/navigation";
import {
  ReactNode,
  createContext,
  useCallback,
  useEffect,
  useState
} from "react";

const app = !(typeof window !== "undefined" && getApps().length)
  ? initializeApp(FirebaseConfig)
  : getApp();

interface FirebaseContextProps {
  tracking: Analytics | null;
}

export const FirebaseContext = createContext<FirebaseContextProps | undefined>(
  undefined
);

export const FirebaseContextProvider = (props: {children: ReactNode}) => {
  const [tracking, setTracking] = useState<Analytics | null>(null);
  const pathname = usePathname();
  const searchParams = useSearchParams();

  const handleRouteChange = useCallback(
    (url: string) => {
      if (tracking) {
        logEvent(tracking, "page_view", {
          page_location: url,
          page_title: document?.title
        });
      }
    },
    [tracking]
  );

  useEffect(() => {
    const initializeFirebase = async () => {
      const analytics = getAnalytics();
      if (!analytics) {
        const initializedAnalytics = initializeAnalytics(app);
        setTracking(initializedAnalytics);
      } else {
        setTracking(analytics);
      }
    };

    initializeFirebase();

    return () => {
      handleRouteChange;
    };
  }, [pathname, searchParams, handleRouteChange]);

  return (
    <FirebaseContext.Provider value={{tracking}}>
      {props.children}
    </FirebaseContext.Provider>
  );
};
